import React from "react";

// Customizable Area Start
import {
  Box, Button, Grid, IconButton, Modal, TextField, Tooltip, Typography
} from "@material-ui/core";
import Contractmanagement2Controller, {
  Props,
} from "./Contractmanagement2Controller";
import './css/styles.css';
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { bankDetailIcon, closeIcon, plusLgIcon, bank_active, closeGrayIcon, backArrowIcon } from "./assets";
import { ErrorMessage, Field, Formik } from "formik";
import Select from 'react-select'
import { currencySettingsSchema, payoutSettingsSchema, updatePayoutSettingsSchema } from "../../../components/src/validations";
import { capitalizeFirstLetter, getCurrentActiveClass, getSavingsActiveClass, getToken, getWrapperClassName } from "../../../components/src/commonUsage";
import PayoutSettingsSkeleton from "./PayoutSettingsSkeleton";
// Customizable Area End

export default class PayoutSettings extends Contractmanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if(getToken('partner_role') == 'member'){
      this.props.navigation.navigate('DashboardWeb')
    }
    this.getCountries()
    this.getBankDetails()
  }

  renderTooltip = (item: any) => {
    return item.status === 'active' ? <Tooltip arrow placement="top" title="Please select or add another bank account before removing this account"
      PopperProps={{
        className: 'large-tooltip'
      }}
    >
      <Button data-test-id="remove-account-button" className="text-btn" disableRipple disabled={item.status === 'active'} onClick={() => this.deleteAccountModal(item)}>Remove</Button>
    </Tooltip> : <Button data-test-id="remove-account-button" className="text-btn" disableRipple disabled={item.status === 'active'} onClick={() => this.deleteAccountModal(item)}>Remove</Button>

  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { id, account_type, account_holder_name, account_number, ifsc_code, status, bank_name, ifsc_type } = this.state.selectedBankData
    return (
      <Box style={webStyle.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper" style={{ paddingBottom: '0' }}>
            {/* add skeleton here  */}
            {this.state.isLoading ?
              <PayoutSettingsSkeleton navigation={undefined} id={""} />
              :
              <Grid>
                <TopNavBar currentPage={{ name: 'Payout settings', path: 'payouts-settings' }} />
                <Box style={webStyle.mainContainer}>
                  <Box className="dashboard-nav-container">
                    <Box className="dashboard-heading">
                      <Box>
                        <Typography className="company-heading-main">
                          Bank account
                        </Typography>
                        <Typography className="deal-heading-sub">
                          Connect a payout option to get payments from commissions
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="bank-accout-detail-wrapper">
                    <Box className="account-listing-wrap">
                      <Box className="add-account-block bank-account-detail-block">
                        <Box data-test-id="add-account-modal" className="detail-inner-wrap" onClick={() => this.addAccountModal()}>
                          <img src={plusLgIcon} alt="" />
                          <Typography className="add account title">Add bank details</Typography>
                        </Box>
                        <div style={{ display: this.state.isFirstTime ? 'block' : 'none' }}>
                          <div className="custom-popover right-side">
                            <div className="type-icon-wrap">
                              <img src={bank_active} alt="Bank details" />
                              <img onClick={() => this.handleOnboardFlow()} className="close-popover" src={closeGrayIcon} alt="Close" />
                            </div>
                            <div className="type-title">Add bank details</div>
                            <div className="type-desc">In order to make sure we can send you the commissions for the referrals converted</div>
                          </div>
                          <div className="custom-backdrop"></div>
                        </div>
                      </Box>
                      {
                        this.state.bank_details?.map((item: any, index: any) => {
                          return <Box data-test-id="render-bank-details" key={index} className="bank-account-detail-block">
                            <Box className="detail-inner-wrap">
                              <Box className="account-title-block">
                                <img src={bankDetailIcon} alt="" />
                                <Typography className="bank-title">{capitalizeFirstLetter(item.bank_name)}</Typography>
                                {
                                  item.status === 'active' &&
                                  <span data-test-id='status-badge-green' className="status-badge green">Default</span>
                                }
                              </Box>
                              <Box className="account-detail-block">
                                <Box className="account-detail-title">
                                  <Typography className="detail-title">Account details</Typography>
                                  <span className="status-badge lg gray">{capitalizeFirstLetter(item.account_type)} account</span>
                                </Box>
                                <Box className="account-description">
                                  <Box className="description-item">
                                    <Typography className="title">Account name</Typography>
                                    <Typography className="content">{item.account_holder_name}</Typography>
                                  </Box>
                                  <Box className="description-item">
                                    <Typography className="title">Account number</Typography>
                                    <Typography className="content">{item.account_number}</Typography>
                                  </Box>
                                  <Box className="description-item">
                                    <Typography className="title">{item.ifsc_type}</Typography>
                                    <Typography className="content">{item.ifsc_code}</Typography>
                                  </Box>
                                </Box>
                                <Box className="account-detail-footer">
                                  <Box className="links-list">
                                    <Button data-test-id="edit-account-button" className="text-btn" disableRipple onClick={() => this.editAccountModal(item)}>Edit details</Button>
                                    {this.renderTooltip(item)}
                                    {
                                      item.status !== 'active' &&
                                      <Button data-test-id="default-account-button" onClick={() => this.setDefaultAccount(item)} className="text-btn" disableRipple>Set as default</Button>
                                    }
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        })
                      }
                    </Box>
                  </Box>
                </Box>
              </Grid >
            }

          </Grid >
        </Grid >
        <Modal
          open={this.state.currencyDetails}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-xs"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              country: this.state.countryValue,
              currency: this.state.currencyLabel,
            }}
            validationSchema={currencySettingsSchema}
            onSubmit={(values) => {
              this.setState({
                currencyDetails: false,
                addAccountModal: true,
                currencySelectData: values
              })
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className="modal-body" style={{overflowY:this.state.addAccountModal?"scroll !important":"unset !important" as any}}>
                  <Box >
                    <>
                      <Box>
                        <IconButton data-test-id="btn-close-country-region" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                        <Typography variant="h4">
                          Let’s add payout method
                        </Typography>
                      </Box>
                      <Box className="form-wrap">
                        <Box className="form-element-wrap account-type-selection">
                          <label className="form-element-label">To start, let us know where you'd like us to pay your commission amount.</label>
                        </Box>
                        <Box className="form-element-wrap mt-1">
                          <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.countryFocus ? "is-focused check-focus" : "is-focused", formik.values.country, this.state.countryFocus)}>
                            <Field name="country" data-test-id="country">
                              {(field: any) => (
                                <>
                                  <label className="form-element-label">Bank country/region</label>
                                  <Select
                                    {...field}
                                    // displayEmpty={true}
                                    value={formik.values.country}
                                    options={this.state.countryData.map((item: any) => {
                                      return {
                                        value: item.label, label: <><span className={`fi fi-${item.code.toLowerCase()}`}></span>{" "}{item.label} </>,
                                      }
                                    })}
                                    name="country"
                                    styles={selectStyle}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    onChange={(option: any) => {
                                      formik.setFieldValue('country', option)
                                      this.setState({ countryValue: option })
                                    }
                                    }
                                    placeholder="Bank country/region"
                                    className="custom-search-select xsmall-listbox no-label"
                                    onFocus={() => this.handleFocus('country')}
                                    onBlur={() => this.handleBlur('country')}
                                  />
                                </>
                              )}
                            </Field>
                            <ErrorMessage className="deal-error-text" name="country" component="div" />
                          </Box>
                        </Box>
                        <Box className="form-element-wrap mt-1">
                          <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.currencyFocus ? "is-focused check-focus" : "is-focused", formik.values.currency, this.state.currencyFocus)}>
                            <Field name="currency" data-test-id="currency">
                              {(field: any) => (
                                <>
                                  <label className="form-element-label">Currency</label>
                                  <Select
                                    {...field}
                                    displayEmpty={true}
                                    value={formik.values.currency}
                                    options={this.state.currencyData}
                                    name="currency"
                                    styles={selectStyle}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    onChange={(option) => {
                                      formik.setFieldValue('currency', option)
                                      this.setState({ currencyLabel: option })
                                    }
                                    }
                                    placeholder="Currency"
                                    className="custom-search-select xsmall-listbox no-label"
                                    onFocus={() => this.handleFocus('currency')}
                                    onBlur={() => this.handleBlur('currency')}
                                  />
                                </>
                              )}
                            </Field>
                            <ErrorMessage className="deal-error-text" name="currency" component="div" />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="mt-1 btn-wrap">
                        <Button
                          data-test-id="btn-close-add-account"
                          fullWidth
                          size="small"
                          variant="contained"
                          type="submit"
                          className="custom-btn full-width green-btn">
                          Setup bank account
                        </Button>
                      </Box>
                    </>
                  </Box >
                </Box>
              </form>
            )}
          </Formik>
        </Modal >
        <Modal
          open={this.state.addAccountModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-xs"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              account_holder_name: '',
              ifsc_code: '',
              account_number: '',
              confirm_account_number: '',
              bank_name: '',
              ifsc_type: {
                value: "IFSC code", label: "IFSC code"
              }
            }}
            validationSchema={
              payoutSettingsSchema
            }
            onSubmit={(values) => {
              this.saveAccountDetails(values)
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className="modal-body">
                  <Box >
                    <Box>
                      <Box className="back-link" onClick={() => this.addAccountModal()}>
                        <img src={backArrowIcon} alt="Back" /> Back
                      </Box>
                      <Box>
                        <IconButton data-test-id="btn-create-close" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                        <Typography variant="h4">
                          Add your bank details to receive payouts
                        </Typography>
                      </Box>
                      <Box className="form-wrap">
                        <Box className="form-element-wrap account-type-selection">
                          <label className="form-element-label">Account type</label>
                          <Box className="deal-btn-outerwrap account-type-btn-wrap">
                            <Box className="deal-lead-btn-wrap">
                              <Button data-test-id="create_savings_account" onClick={() => this.handleAccountsType('savings')} type="button" disableRipple className={getSavingsActiveClass(this.state.account_type, 'custom-btn')}>Savings</Button>
                              <Button data-test-id="create_current_account" onClick={() => this.handleAccountsType('current')} type="button" disableRipple className={getCurrentActiveClass(this.state.account_type, 'custom-btn')}>Current</Button>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="form-element-wrap mt-1">
                          <label className="form-element-label">Account holder name</label>
                          <TextField
                            data-test-id="create_account_holder_name"
                            name="account_holder_name"
                            size="small"
                            variant="filled"
                            type="text"
                            label=""
                            fullWidth={true}
                            className="secondary-form-control"
                            placeholder="Name"
                            value={formik.values.account_holder_name}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                            error={this.getFormikError(formik.touched.account_holder_name, formik.errors.account_holder_name)}
                            helperText={this.getFormikHelperText(formik.touched.account_holder_name, formik.errors.account_holder_name)}
                          />
                        </Box>
                        <Box className="form-element-wrap mt-1">
                          <label className="form-element-label">Bank name</label>
                          <TextField
                            data-test-id="create_bank_name"
                            name="bank_name"
                            size="small"
                            variant="filled"
                            type="text"
                            label=""
                            fullWidth={true}
                            className="secondary-form-control"
                            placeholder="Bank Name"
                            value={formik.values.bank_name}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                            error={this.getFormikError(formik.touched.bank_name, formik.errors.bank_name)}
                            helperText={this.getFormikHelperText(formik.touched.bank_name, formik.errors.bank_name)}
                          />
                        </Box>

                        <Box className="form-element-wrap mt-1">
                          <label className="form-element-label">Account number</label>
                          <TextField
                            data-test-id="create_account_number"
                            name="account_number"
                            size="small"
                            variant="filled"
                            type="text"
                            label=""
                            fullWidth={true}
                            className="secondary-form-control"
                            placeholder="000123456789"
                            value={formik.values.account_number}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                            error={this.getFormikError(formik.touched.account_number, formik.errors.account_number)}
                            helperText={this.getFormikHelperText(formik.touched.account_number, formik.errors.account_number)}

                          />
                        </Box>
                        <Box className="form-element-wrap mt-1">
                          <label className="form-element-label">Confirm account number</label>
                          <TextField
                            data-test-id="create_confirm_account_number"
                            name="confirm_account_number"
                            size="small"
                            variant="filled"
                            type="text"
                            label=""
                            fullWidth={true}
                            className="secondary-form-control"
                            placeholder="000123456789"
                            value={formik.values.confirm_account_number}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                            error={this.getFormikError(formik.touched.confirm_account_number, formik.errors.confirm_account_number)}
                            helperText={this.getFormikHelperText(formik.touched.confirm_account_number, formik.errors.confirm_account_number)}

                          />
                        </Box>
                        <Box className="form-element-wrap mt-1">
                          <label className="form-element-label">Bank code</label>
                          <Grid item xs={12} md={12}>
                            <div className={getWrapperClassName("input-with-adornment ifsc-dropdown", this.state.ifscTypeFocus ? "is-focused check-focus" : "is-focused", formik.values.ifsc_type, this.state.ifscTypeFocus)}>
                              <Field name="ifsc_type">
                                {(field: any) => (
                                  <Select
                                    data-test-id=""
                                    {...field}
                                    name="ifsc_type"
                                    styles={selectStyle}
                                    options={[
                                      { value: 'IBAN code', label: 'IBAN code' },
                                      { value: 'Swift code', label: 'Swift code' },
                                      { value: 'IFSC code', label: 'IFSC code' },
                                    ]}
                                    className="custom-search-select xsmall-listbox no-label"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    onChange={(option) => {
                                      formik.setFieldValue('ifsc_type', option)
                                    }
                                    }
                                    value={formik.values.ifsc_type}
                                    onFocus={() => this.handleFocus('ifsc_type')}
                                    onBlur={() => this.handleBlur('ifsc_type')}
                                  />
                                )}
                              </Field>
                              <TextField
                                data-test-id="create_ifsc_code"
                                name="ifsc_code"
                                size="small"
                                variant="filled"
                                type="text"
                                label=""
                                fullWidth={true}
                                className="secondary-form-control"
                                placeholder="000123456789"
                                value={formik.values.ifsc_code}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                }}
                                onFocus={() => this.handleFocus('ifsc_type')}
                                onBlur={() => this.handleBlur('ifsc_type')}
                              />
                            </div>
                            <ErrorMessage className="deal-error-text" name="ifsc_type" component="div" />
                            <ErrorMessage className="deal-error-text" name="ifsc_code" component="div" />
                          </Grid>
                        </Box>

                      </Box>
                    </Box >
                    <Box className="mt-1 btn-wrap">
                      <Button
                        data-test-id="btn-close-add-account"
                        fullWidth
                        size="small"
                        variant="contained"
                        type="submit"
                        className="custom-btn full-width green-btn">
                        Save bank details
                      </Button>
                    </Box>
                    <Box className="mt-1">
                      <Typography className="deal-error-text">
                        {this.state.errorMessage}
                      </Typography>
                    </Box>
                  </Box >
                </Box>
              </form>
            )}
          </Formik>
        </Modal >
        <Modal
          open={this.state.editAccountModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-xs"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              id: id,
              account_holder_name: account_holder_name,
              ifsc_code: ifsc_code,
              account_number: account_number,
              confirm_account_number: account_number,
              bank_name: bank_name,
              ifsc_type: {
                value: ifsc_type, label: ifsc_type
              }
            }}
            validationSchema={updatePayoutSettingsSchema}
            onSubmit={(values) => {
              this.updateAccountDetails(values, status)
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className="modal-body">
                  <Box>
                    <IconButton data-test-id="btn-update-close" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                    <Typography variant="h4">
                      Bank account details
                    </Typography>
                  </Box>
                  <Box className="form-wrap">
                    <Box className="form-element-wrap account-type-selection">
                      <label className="form-element-label">Account type</label>
                      <Box className="deal-btn-outerwrap account-type-btn-wrap">
                        <Box className="deal-lead-btn-wrap">
                          <Button data-test-id="update_savings_account" onClick={() => this.handleAccountsType('savings')} type="button" disableRipple className={getSavingsActiveClass(this.state.account_type, 'custom-btn')}>Savings</Button>
                          <Button data-test-id="update_current_account" onClick={() => this.handleAccountsType('current')} type="button" disableRipple className={getCurrentActiveClass(this.state.account_type, 'custom-btn')}>Current</Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-element-wrap mt-1">
                      <label className="form-element-label">Account holder name</label>
                      <TextField
                        data-test-id="update_account_holder_name"
                        name="account_holder_name"
                        size="small"
                        variant="filled"
                        type="text"
                        label=""
                        fullWidth={true}
                        className="secondary-form-control"
                        placeholder="Name"
                        value={formik.values.account_holder_name}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        error={this.getFormikError(formik.touched.account_holder_name, formik.errors.account_holder_name)}
                        helperText={this.getFormikHelperText(formik.touched.account_holder_name, formik.errors.account_holder_name)}

                      />
                    </Box>
                    <Box className="form-element-wrap mt-1">
                      <label className="form-element-label">Bank name</label>
                      <TextField
                        data-test-id="update_bank_name"
                        name="bank_name"
                        size="small"
                        variant="filled"
                        type="text"
                        label=""
                        fullWidth={true}
                        className="secondary-form-control"
                        placeholder="Name"
                        value={formik.values.bank_name}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        error={this.getFormikError(formik.touched.bank_name, formik.errors.bank_name)}
                        helperText={this.getFormikHelperText(formik.touched.bank_name, formik.errors.bank_name)}

                      />
                    </Box>

                    <Box className="form-element-wrap mt-1">
                      <label className="form-element-label">Account number</label>
                      <TextField
                        data-test-id="update_account_number"
                        name="account_number"
                        size="small"
                        variant="filled"
                        type="text"
                        label=""
                        fullWidth={true}
                        className="secondary-form-control"
                        placeholder="000123456789"
                        disabled={true}
                        value={formik.values.account_number}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        error={this.getFormikError(formik.touched.account_number, formik.errors.account_number)}
                        helperText={this.getFormikHelperText(formik.touched.account_number, formik.errors.account_number)}

                      />
                    </Box>
                    <Box className="form-element-wrap mt-1">
                      <label className="form-element-label">Bank code</label>
                      <Grid item xs={12} md={12}>
                      <div className={getWrapperClassName("input-with-adornment ifsc-dropdown", this.state.ifscTypeFocus ? "is-focused check-focus" : "is-focused", formik.values.ifsc_type, this.state.ifscTypeFocus)}>
                          <Field name="ifsc_type">
                            {(field: any) => (
                              <Select
                                data-test-id=""
                                {...field}
                                name="ifsc_type"
                                styles={selectStyle}
                                options={[
                                  { value: 'IBAN code', label: 'IBAN code' },
                                  { value: 'Swift code', label: 'Swift code' },
                                  { value: 'IFSC code', label: 'IFSC code' },
                                ]}
                                className="custom-search-select xsmall-listbox no-label"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                onChange={(option) => {
                                  formik.setFieldValue('ifsc_type', option)
                                }
                                }
                                value={formik.values.ifsc_type}
                                onFocus={() => this.handleFocus('ifsc_type')}
                                onBlur={() => this.handleBlur('ifsc_type')}
                              />
                            )}
                          </Field>
                          <TextField
                            data-test-id="update_ifsc_code"
                            name="ifsc_code"
                            size="small"
                            variant="filled"
                            type="text"
                            label=""
                            fullWidth={true}
                            className="secondary-form-control"
                            placeholder="000123456789"
                            value={formik.values.ifsc_code}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                            onFocus={() => this.handleFocus('ifsc_type')}
                            onBlur={() => this.handleBlur('ifsc_type')}
                          />
                        </div>
                        <ErrorMessage className="deal-error-text" name="ifsc_code" component="div" />
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="mt-1 btn-wrap">
                    <Button
                      data-test-id="btn-close-add-account"
                      fullWidth
                      size="small"
                      variant="contained"
                      type="submit"
                      className="custom-btn full-width green-btn">
                      Edit details
                    </Button>
                  </Box>
                  <Box className="mt-1">
                    <Typography className="deal-error-text">
                      {this.state.errorMessage}
                    </Typography>
                  </Box>
                </Box >
              </form>
            )}
          </Formik>
        </Modal>
        <Modal
          open={this.state.deleteAccountModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-xs account-delete-modal"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id="btn-remove-account" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Typography variant="h4">
                Delete bank details?
              </Typography>
            </Box>
            <Box className="">
              <Box className="bank-account-detail-block">
                <Box className="detail-inner-wrap">
                  <Box className="account-detail-block">
                    <Box className="account-detail-title">
                      <Typography className="detail-title">Account details</Typography>
                      <span className="status-badge lg gray">{`${capitalizeFirstLetter(account_type)} account`}</span>
                    </Box>
                    <Box className="account-description">
                      <Box className="description-item">
                        <Typography className="title">Bank name</Typography>
                        <Typography className="content">{capitalizeFirstLetter(bank_name)}</Typography>
                      </Box>
                      <Box className="description-item">
                        <Typography className="title">Account holder name</Typography>
                        <Typography className="content">{capitalizeFirstLetter(account_holder_name)}</Typography>
                      </Box>
                      <Box className="description-item">
                        <Typography className="title">Account number</Typography>
                        <Typography className="content">{account_number}</Typography>
                      </Box>
                      <Box className="description-item">
                        <Typography className="title">{ifsc_type}</Typography>
                        <Typography className="content">{ifsc_code}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography className="note-text">Please note: By deleting your bank account your bank account details shall be removed but all your previous transaction history shall remain</Typography>
            <Box className="mt-1 btn-wrap">
              <Button
                data-test-id="btn-remove-close-modal"
                onClick={() => {
                  this.closeModal()
                }}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn border-btn">
                No
              </Button>
              <Button
                data-test-id="btn-delete-bank-account"
                onClick={() => {
                  this.deleteBankAccount()
                }}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn green-btn">
                Yes
              </Button>
            </Box>
          </Box >
        </Modal>
      </Box >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0 0'
  },
};
const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 56,
    border: '1px solid #C0C3CE',
    borderRadius: 8,
    boxShadow: 'none',
    '&:hover': {
      // border: '1px solid #C0C3CE',
      boxShadow: 'none'
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '6px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
};
// Customizable Area End
